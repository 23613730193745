<!-- eslint-disable -->
<template>
  <DocPage :titles="titles" title="AML/KYC Policy">
    <div>
      <h1 id="Introduction">Introduction</h1>
      <br />
      The Anti-Money Laundering and Know Your Customer Policy (the “AML/KYC
      Policy”) is established to prevent and mitigate possible risks of Inst
      Money ("Inst", "we", "us", or "our") being involved in illegal or illicit
      activities and to enable Inst to meet its legal and regulatory obligations
      in this area (if any, where applicable). This AML/KYC Policy is subject to
      changes and updates by Inst from time to time to ensure compliance with
      any applicable legislation and global AML/KYC practices. <br /><br />
      <br />
      <h2 id="KYC/AML Identification Procedures">
        KYC/AML Identification Procedures
      </h2>
      <br /><br />
      Inst adopts a risk-based approach to combating money laundering and
      terrorist financing. Prior to enabling or entering into a Transaction with
      or for or on behalf of a User or providing any Service to a User, Inst
      will, if so required by applicable law or if it is otherwise deemed
      necessary or expedient:<br /><br />
      (a) identify the User and verify the User’s identity on the basis of
      documents, data or other information based on a reliable and independent
      source;<br />
      (b) if there is a Beneficial Owner in relation to the User, identify the
      beneficial owner and take reasonable measures to verify the beneficial
      owner’s identity;<br />
      (c) obtain information on the purpose and intended nature of the business
      relationship with the User, unless the purpose and intended nature are
      clearly stipulated in the relevant documentation between Inst and the
      User；As part of this process, Inst shall obtain, amongst other matters,
      information on the source of funds and source of wealth of the User; To
      identify a User who is an individual, Inst will collect information from
      the User, including but not limited to, his full name, date of birth,
      place of birth, nationality, place of residence, email address, and the
      identity document type. Inst will verify the identity of the User with
      documents such as his national ID, passport and/or driver’s licence and
      utility bill.<br />
      <br />
      <h2 id="Ongoing Monitoring of Users">Ongoing Monitoring of Users</h2>
      <br /><br />
      Inst reserves the right to continuously monitor, on a risk sensitive
      basis, the business relationship with a User (as applicable) by:<br /><br />
      (a) reviewing from time to time documents, data and information that have
      been obtained by Inst to ensure that such documents, data and information
      are up to date;<br /><br />
      (b) conducting appropriate scrutiny of Transactions and activities carried
      out by Users to ensure that they are consistent with Inst’s knowledge of
      the User’s business and risk profile, and to ensure that such Transactions
      and activities are in line with Inst’s knowledge of the User’s or User’s
      source of funds and source of wealth; and<br /><br />
      (c) identifying transactions that are unusually large in amount or of an
      unusual pattern and have no apparent economic or lawful purpose.<br /><br />
      <br />
      <h2
        id="Sanctioned Jurisdictions, Prohibited Jurisdictions and High Risk Jurisdictions"
      >
        Sanctioned Jurisdictions, Prohibited Jurisdictions and High Risk
        Jurisdictions
      </h2>
      <br /><br />
      Inst will establish and maintain the following lists of jurisdictions (i)
      Sanctioned Jurisdictions (ii) Prohibited Jurisdictions and (iii) High Risk
      Jurisdictions. In determining the list of Sanctioned Jurisdictions,
      Prohibited Jurisdictions and High Risk Jurisdictions, Inst shall take into
      account the lists issued by the Financial Action Task Force and guidelines
      issued by other organizations and lists relating to the adequacy of
      legislative measures adopted by jurisdictions in relation to money
      laundering, funding of terrorism and transparency. Users which are (i)
      resident or domiciled in, or (ii) have their source of wealth or source of
      funds linked to a Sanctioned Jurisdiction and/or a Prohibited Jurisdiction
      shall not be accepted as clients of Inst. Users which are (i) resident or
      domiciled in, or (ii) have their source of wealth or source of funds
      linked to High Risk Jurisdictions shall be subject to additional checks
      and measures by Inst. <br /><br />
      <br />
      <h2 id="Record-keeping">Record-keeping</h2>
      <br /><br />
      Inst will keep (a) transaction records and (b) other information collected
      by Inst for AML/KYC purposes, throughout the continuance of the business
      relationship with the User and for periods as may be required by
      applicable law.<br /><br />
      <br />
    </div>
  </DocPage>
</template>

<script>
import DocPage from '@/views/DocPage.vue';
/**
 * @author yuanzeyu
 * @date 2020-02-11
 * @desc
 */
export default {
  name: 'Privacy',
  components: { DocPage },
  data() {
    return {
      titles: [
        'Introduction',
        'KYC/AML Identification Procedures',
        'Ongoing Monitoring of Users',
        'Sanctioned Jurisdictions, Prohibited Jurisdictions and High Risk Jurisdictions',
        'Record-keeping',
        'More information',
      ],
    };
  },
};
</script>
